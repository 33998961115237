<template>
  <div class="p-5 xl:px-0">
    <h1 class="mb-4 text-lg">Users</h1>
    <transition name="fade" mode="out-in">
      <FlashMessage
        message="loading..."
        v-if="loading && !users.length"
        key="loading"
      />

      <div class="flex flex-col" v-else>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  School
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Role
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, userIdx) in users" :key="user.id" :class="userIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                  {{ user.name }}
                </td>
                
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ user.email }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ user.school.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ user.roles.join(', ').replace(/-/g, ' ') | TitleCase }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap font-medium flex justify-end">
                  <modal title="Change password" class=" cursor-pointer" :attributes="{ payload: user }" component="PasswordUpdate" width="lg" buttonClass="mx-1" v-permission="['users.edit']">
                    <font-awesome-icon icon="key" />
                  </modal>

                  <modal title="User" class="cursor-pointer" :attributes="{ payload: user }" component="User" width="lg" buttonClass="mx-1" v-permission="['users.edit']">
                    <font-awesome-icon icon="pen" />
                  </modal>

                  <modal title="Are you sure?" class="cursor-pointer ml-6 " buttonClass="text-red-600" component="DeleteConfirm" :attributes="{ message: 'Are you sure you want to delete this user?', id: user.id, resource: 'users' }"  v-permission="['users.delete']">
                    <font-awesome-icon icon="trash" />
                  </modal>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

    </transition>
    <transition name="fade">
      <FlashMessage :error="error" v-if="error" key="error" />
    </transition>
    <transition name="fade">
      <BasePagination
        path="users"
        :meta="meta"
        :links="links"
        action="user/paginateUsers"
        v-if="meta && meta.last_page > 1"
      />
    </transition>

    <footer class="fixed left-0 bottom-0 w-full bg-gray-900" v-permission="['users.edit']">
      <div class="flex items-center justify-between flex-wrap bg-teal-500 p-6 container mx-auto">
        <modal title="User" class="cursor-pointer" component="User" width="lg">
          <BaseBtn type="span" icon="plus" text="Add User" class="border-white" />
        </modal>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import FlashMessage from "@/components/FlashMessage";
import BasePagination from "@/components/BasePagination";
import BaseBtn from "@/components/BaseBtn";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPen, faKey, faTrash} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPen, faKey, faTrash);

export default {
  name: "UsersView",
  components: { FlashMessage, BasePagination, FontAwesomeIcon, BaseBtn },
  computed: {
    ...mapGetters("user", ["loading", "error", "users", "meta", "links"]),
  },
  created() {
    this.$eventBus.$on("users:updated", (val) => {
      this.getUsers();
      this.message = 'User updated'
    });
    this.$eventBus.$on("users:added", (val) => {
      this.getUsers();
      this.message = 'User added'
    });
    this.$eventBus.$on("users:password", (val) => {
      this.message = 'Password updated'
    });
    this.$eventBus.$on("record:deleted", (val) => {
      this.getUsers();
      this.message = 'User deleted'
    });
  },
  methods: {
    getUsers() {
      const currentPage = 1;
      this.$store.dispatch("user/getUsers", currentPage);
    },
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("user/getUsers", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  },
};
</script>
